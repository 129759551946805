<template>

    <div class="oxygen-calculator">

        <div class="content">
            <oxygen-calculator-background :landingPage="landingPage"></oxygen-calculator-background>

            <transition name="fade" mode="out-in">
                <router-view @hide::landingContent="hideLandingContent" @show::landingContent="showLandingContent"></router-view>
            </transition>
        </div>

        <div class="orientation"><img src="@/assets/images/global/img-orientation.png"></div>

    </div>

</template>


<script>

import OxygenCalculatorBackground from '@/components/oxygen-calculator/OxygenCalculatorBackground'

export default {

    name: 'OxygenCalculator',

    metaInfo() {
        return {
            title: 'Calculate Extra Os',
            meta: [
                {
                    name: 'description',
                    content: 'Use this calculator to determine how much oxygen will you need for an expedition to the Everest summit and back.'
                }, {
                    name: 'keywords',
                    content: 'national geographic, natgeo, everest, mt everest, mt. everest, mount everest, climbing, summit, summiting, explore, calculator, calculate, oxygen, o2, tank, air, flow, rate'
                }, {
                    property: 'og:title',
                    content: 'Calculate Extra Os'
                }, {
                    property: 'og:type',
                    content: 'website'
                }, {
                    property: 'og:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/calculate-extra-os.html'
                }, {
                    property: 'og:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-facebook_v01_ls_0000_02Calc.jpg'
                }, {
                    property: 'site_name',
                    content: 'National Geographic : Everest Educational Interactives'
                }, {
                    property: 'og:description',
                    content: 'Use this calculator to determine how much oxygen will you need for an expedition to the Everest summit and back.'
                }, {
                    property: 'twitter:card',
                    content: 'summary_large_image'
                }, {
                    property: 'twitter:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/calculate-extra-os.html'
                }, {
                    property: 'twitter:title',
                    content: 'Calculate Extra Os'
                }, {
                    property: 'twitter:description',
                    content: 'Use this calculator to determine how much oxygen will you need for an expedition to the Everest summit and back.'
                }, {
                    property: 'twitter:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-twitter_2-1_v01_ls_0000_02Calc.jpg'
                }
            ]
        };
    },

    components: {
        OxygenCalculatorBackground
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            landingPage: true
        };
    },

    created() {
        
    },

    mounted() {
        
    },

    methods: {
        /**
         * @function hideLandingContent
         * @description
         *
         */
        hideLandingContent() {
            this.landingPage = false
        },
        /**
         * @function showLandingContent
         * @description
         *
         */
        showLandingContent() {
            this.landingPage = true
        }
    }

}

</script>


<style lang="scss" scoped>

.oxygen-calculator {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    & .content {
        display: block;
        background: linear-gradient(155deg,  #cddff7 5%,#fff 50%,#fff 100%);
        position: absolute;
        width: 100%;
        height: 100%;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
            display: none;
        }
    }

    & .orientation {
        position: fixed;
        display: none;
        width: 100%;
        height: calc(100vh - 100px);
        width: 100vw;
        height: 100%;
        z-index: 100;
        transform: translateZ(10001px);
        object-fit: contain;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column nowrap;
        }

        & img {
            height: 100%;
            object-fit: contain;
        }

    }
}

</style>
