<template>

    <div class="sky-box"
         :style="{ 'background-image': 'url(' + image + ')', 'background-position': '0px ' + yPos + 'px' }"></div>

</template>


<script>

import { gsap, Linear } from 'gsap'

let vm = {} // eslint-disable-line no-unused-vars

export default {

    name: 'SkyBox',

    props: {
        image: {
            type: String
        },
        width: {
            type: Number,
            default: 1920
        },
        height: {
            type: Number,
            default: 1018
        },
        direction: {
            type: String,
            default: 'ltr'
        },
        yPos: {
            type: Number,
            default: 0
        },
        speed: {
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            tween: null,
            mq: {
                prefersReducedMotion: window.matchMedia("(prefers-reduced-motion: reduce)")
            }
        }
    },

    created() {
        this.mq.prefersReducedMotion.addEventListener('change', this.handleReducedMotionChange, false)
    },

    mounted() {
        vm = this
        this.initSkyBox()
    },

    methods: {

        /**
         * @function initSkyBox
         * @description Initialize SkyBox infinite scrolling background image.
         */
        initSkyBox() {
            if (!this.image) { return; }
            this.scrollImg = new Image()
            this.scrollImg.onload = this.loaded
            this.scrollImg.src = this.image
        },

        /**
         * @function loaded
         * @description Image loaded event handler.
         */
        loaded() {

            this.tween = gsap.to(this.$el, {
                duration: this.speed,
                backgroundPosition: this.direction === 'rtl' ? -Math.abs(this.width) : this.width + 'px ' + this.yPos + 'px',
                force3D: true,
                rotation: 0.01,
                z: 0.01,
                repeat: -1,
                ease: Linear.easeNone
            })

        },

        /**
         * @function handleReducedMotionChange
         * @description
         * @param {Object} evt
         */
        handleReducedMotionChange(evt) {
            if (!evt.matches) {
                this.loaded()
            } else {
                this.tween.kill()
            }
        }

    },

    beforeDestroy() {
        this.mq.prefersReducedMotion.removeEventListener('change', this.handleReducedMotionChange, false)
        this.tween.kill()
    }

}

</script>


<style lang="scss" scoped>

.sky-box {
    background-repeat: repeat-x;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: screen;
    transform: translate3d(0, 0, 0.01px);
    will-change: transform;
    pointer-events: none;
    opacity: .7;
    //display: none;

    @media (min-width: 576px) { // RED (SM)
        //display: block;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }
}

</style>
