<template>
    
    <div class="oxygen-calculator-background">
        <img src="@/assets/images/oxygen-calculator/img-landing-waves.png" class="landing-waves">
        <img src="@/assets/images/oxygen-calculator/img-landing-texture.png" class="landing-texture">
        <transition name="fade" mode="out-in">
            <img src="@/assets/images/oxygen-calculator/img-landing-badge.png" alt="landing badge" class="landing-badge" v-if="landingPage">
        </transition>
        <transition name="fade" mode="out-in">
            <div class="heading" v-if="landingPage">
                <h1>Calculate</h1>
                <h2>EXTRA Os</h2>
            </div>
        </transition>
        <img src="@/assets/images/oxygen-calculator/img-landing-mtn.png" class="landing-mtn">
        <img src="@/assets/images/oxygen-calculator/img-landing-mtn-mobile.png" class="landing-mtn-mobile">
        <sky-box :id="skyBoxes[0].id"
                    :class="skyBoxes[0].id"
                    :image="skyBoxes[0].image"
                    :width="skyBoxes[0].width"
                    :height="skyBoxes[0].height"
                    :direction="skyBoxes[0].direction"
                    :yPos="skyBoxes[0].verticalPosition"
                    :speed="skyBoxes[0].speed"></sky-box>

        <sky-box :id="skyBoxes[1].id"
                    :class="skyBoxes[1].id"
                    :image="skyBoxes[1].image"
                    :width="skyBoxes[1].width"
                    :height="skyBoxes[1].height"
                    :direction="skyBoxes[1].direction"
                    :yPos="skyBoxes[1].verticalPosition"
                    :speed="skyBoxes[1].speed"></sky-box>
    </div>
    
</template>


<script>
import SkyBox from '@/components/oxygen-calculator/SkyBox'

export default {
    
    name: 'OxygenCalculatorBackground',

    components: {
        SkyBox
    },

    props: ['landingPage'],

    data() {
        return {
            componentName: this.$options.name + ' View',
            skyBoxes: [
                {
                    id: 'sky-box--clouds-fg',
                    image: require('../../assets/images/oxygen-calculator/img-clouds-fg.png'),
                    width: 1920,
                    height: 1018,
                    speed: 300,
                    direction: 'rtl',
                    verticalPosition: 400
                }, {
                    id: 'sky-box--clouds-bg',
                    image: require('../../assets/images/oxygen-calculator/img-clouds-bg.png'),
                    width: 1920,
                    height: 1018,
                    speed: 300,
                    direction: 'ltr',
                    verticalPosition: 350
                }
            ]
        }
    }
}
</script>


<style lang="scss" scoped>
    
    .oxygen-calculator-background {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .landing-waves {
        position: absolute;
        width: 100%;
        left: 0;
        mix-blend-mode: color-dodge;
        opacity: .5;
        z-index: 10;
    }

    .landing-texture {
        position: absolute;
        width: 100%;
        left: 0;
        mix-blend-mode: difference;
        opacity: .2;
        z-index: 15;
    }

    .landing-mtn-mobile {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 20;
        display: none;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:portrait) {
            display: block;
        }
    }

    .landing-mtn {
        position: absolute;
        width: 200%;
        left: -51%;
        top: 240px;
        z-index: 20;
        display: block;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:portrait) {
            display: none;
        }

        @media (min-width: 576px) { // RED (SM)
            width: 170%;
            left: -35.5%;
            top: 290px;
        }
        @media (min-width: 768px) { // GREEN (MD)
            width: 150%;
            left: -25%;
            top: 320px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            width: 130%;
            left: -15%;
            top: 335px;
        }
        @media (min-width: 1200px) { // YELLOW (XL)
            width: 110%;
            left: -5%;
            top: 335px;
        }
        @media (min-width: 1400px) { // PURPLE (XXL)
            width: 100%;
            left: 0%;
            top: 335px;
        }
    }

    .landing-badge {
        position: relative;
        width: 87px;
        margin-top: 45px;
        z-index: 25;
    }

    .heading {
        position: relative;
        z-index: 18;

        h1 {
            text-transform: uppercase;
            color: #22226b;
            font-size: 35px;
            font-weight: $font-weight-semibold;
            letter-spacing: 7px;
            margin: 15px 0 -5px 0;
            user-select: none;
            
            @media (min-width: 576px) { // RED (SM)
                font-size: 60px;
            }
            @media (min-width: 768px) { // GREEN (MD)
                font-size: 65px;
            }
            @media (min-width: 992px) { // BLUE (LG)
                font-size: 70px;
            }
            @media (min-width: 1200px) { // YELLOW (XL)
                font-size: 70px;
            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        h2 {
            background: linear-gradient(to right, #20215e, #3a67a3, #67becc);
            color: #22226b;
            font-size: 60px;
            font-weight: $font-weight-extrabold;
            letter-spacing: 3px;
            margin: 0;
            user-select: none;
            display: inline-block;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;

            @media (min-width: 576px) { // RED (SM)
                font-size: 100px;
            }
            @media (min-width: 768px) { // GREEN (MD)
                font-size: 135px;
            }
            @media (min-width: 992px) { // BLUE (LG)
                font-size: 150px;
            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }
    }

    // SKY-BOX--CLOUDS-FG
    .sky-box--clouds-fg {
        //background-color: rgba(30,144,255, 0.5);

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 22;
        pointer-events: none;
    }

    // SKY-BOX--CLOUDS-BG
    .sky-box--clouds-bg {
        //background-color: rgba(0,250,0, 0.5);

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 19;
        pointer-events: none;
    }

</style>